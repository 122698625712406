import Vue from 'vue';
import Router from 'vue-router';
import paths from './paths';
import store from '../store';

Vue.use(Router);
const router = new Router({
    base: '/',
    linkActiveClass: 'active',
    mode: 'history',
    routes: paths
});

let path = '/';

router.beforeEach((to, from, next) => {
    if (to.name !== from.name) {
        if (to.matched.some(record => !record.meta.public)) {
            if (store.getters.isLoggedIn) {
                next();
                return;
            }
            next("/login");
        } else {
            if (to.name === "Login") {}
            next();
        }
    }
});

router.afterEach((to, from, next) => {
    setTimeout(offLoading, 1400);

})

function offLoading() {
    store.dispatch('setLoading', false)
}

export default router;
