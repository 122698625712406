<template>
  <div id="appRoot">
    <template v-if="!$route.meta.public && !loading && !$route.meta.fullPage">
      <v-app id="inspire">
        <app-toolbar/>
        <v-main class="page-wrapper mt-8">
          <v-row class="pa-0">
            <v-col cols="2" class="pa-0 pt-2">
              <app-drawer/>
            </v-col>
            <v-col cols="10" class="pa-0 pl-4">
              <router-view/>
            </v-col>
          </v-row>
        </v-main>
      </v-app>
    </template>
    <template v-else-if="$route.meta.fullPage">
      <v-app id="inspire">
        <app-toolbar/>
        <v-main class="page-wrapper mt-8">
          <v-row class="pa-0">
            <v-col cols="12" class="pa-0 pl-4">
              <router-view/>
            </v-col>
          </v-row>
        </v-main>
      </v-app>
    </template>
    <template v-else-if="!loading">
      <v-app-bar app height="74" flat outlined>
        <v-img src="/static/images/logo.svg" height="16px" contain/>
      </v-app-bar>
      <v-app class="primary page-wrapper">
        <keep-alive>
          <router-view :key="$route.fullpath"/>
        </keep-alive>
      </v-app>
    </template>
    <error-dialog :global-error-dialog="globalErrorDialog"
                  :global-error-message="globalErrorMessage"
                  @click="setGlobalErrorMessage"
    />
    <loading v-if="loading"/>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import AppDrawer from "@/components/AppDrawer";
import AppToolbar from "@/components/AppToolbar";
import Loading from "@/views/Loading";

export default {
  components: {
    Loading,
    AppDrawer,
    AppToolbar,
  },
  data: () => ({
    snackbar: {
      show: false,
      text: "",
      color: ""
    }
  }),
  computed: {
    ...mapGetters(['globalErrorDialog', 'globalErrorMessage', 'errorMessages', 'loading'])
  },
  watch: {
    width() {
      this.setWindowWidth(this.width);
    }
  },
  methods: {
    ...mapActions(['setGlobalErrorMessage', 'init']),
    getWidth() {
      window.onresize = () => {
        this.width = document.body.clientWidth;
      };
    },
    handleDrawerVisible() {
      this.showDrawer = !this.showDrawer
    },
  },
  created() {
    let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    document.getElementsByTagName('head')[0].appendChild(link);

    this.width = document.body.clientWidth;
    this.init()

    window.getApp = this;


    // if (!localStorage.getItem('token')) {
    //   this.$store.dispatch("logout");
    //   this.$router.push("/login");
    // }

    // this.$http.interceptors.response.use(
    //     response => {
    //       return response;
    //     },
    //     err => {
    //       return new Promise((resolve, reject) => {
    //         if (err?.response?.status === 401 || err?.response?.status === 403) {
    //           this.$store.dispatch("logout");
    //           this.$router.push("/login");
    //         } else {
    //           reject(err);
    //         }
    //       });
    //     }
    // );
  },
};
</script>
