import http from "../plugins/http";
import store from "../store";

export default [
    {
        path: "*",
        meta: {
            public: true
        },
        redirect: {
            path: "/404"
        }
    },
    {
        path: "/404",
        meta: {
            public: true
        },
        name: "NotFound",
        component: () => import(`@/views/error/NotFound.vue`)
    },
    {
        path: "/403",
        meta: {public: true},
        name: "AccessDenied",
        component: () => import(`@/views/error/Deny.vue`)
    },
    {
        path: "/500",
        meta: {public: true},
        name: "ServerError",
        component: () => import(`@/views/error/Error.vue`)
    },
    {
        path: "/login",
        meta: {public: true},
        name: "Login",
        component: () => import(`@/views/Login.vue`)
    },
    {
        path: "/password/restore/:secret",
        meta: {public: true},
        name: "restorePassword",
        component: () => import(`@/views/RestorePassword.vue`),
        beforeEnter(routeTo, _routeFrom, next) {
            if (!!routeTo.params.secret) {
                http.get(`/verify/password/restore?secret=${routeTo.params.secret}`)
                    .then(r => {
                            next()
                            store.dispatch('setPasswordRestore', {
                                user: r.data,
                                secret: routeTo.params.secret
                            })
                        },
                        () => next({name: 'NotFound'})
                    )
                    .catch(() => next({name: 'NotFound'}))
            } else {
                next({name: 'NotFound'})
            }
        },
    },
    {
        path: "/",
        meta: {},
        name: "Root",
        redirect: {
            name: "shopping"
        }
    },
    {
        path: "/shopping",
        meta: {breadcrumb: true},
        name: "shopping",
        component: () =>
            import(
                `@/views/Shopping/shopping-personal/index.vue`)
    },
    {
        path: "/account",
        meta: {account: true},
        name: "account",
        component: () =>
            import(
                `@/views/dict/Account.vue`)
    },
    {
        path: "/payment",
        meta: {account: true},
        name: "payment",
        component: () =>
            import(
                `@/views/dict/Payment.vue`)
    },
    {
        path: "/status",
        meta: {account: true},
        name: "status",
        component: () =>
            import(
                `@/views/dict/Status.vue`)
    },
    {
        path: "/add-products",
        meta: {account: true},
        name: "addProducts",
        component: () =>
            import(
                `@/views/AddProducts.vue`)
    },
    {
        path: "/shop/:id",
        meta: {fullPage: true},
        name: "shop",
        component: () =>
            import(
                `@/views/Shopping/shopping-personal/shop/index.vue`)
    },
    {
        path: "/shop",
        meta: {breadcrumb: true},
        name: "shop",
        component: () =>
            import(
                `@/views/Shop.vue`)
    },
    {
        path: "/shopping-process/:id",
        meta: {breadcrumb: true},
        name: "shopping-process",
        component: () =>
            import(
                `@/views/Shopping/ShoppingProcess.vue`)
    },
    {
        path: "/edit/:id",
        meta: {breadcrumb: true},
        name: "edit",
        component: () =>
            import(
                `@/views/Shopping/ShoppingProcess.vue`)
    },
    {
        path: "/users",
        meta: {breadcrumb: true},
        name: "users",
        component: () =>
            import(
                `@/views/Users/index.vue`)
    },
    {
        path: "/permission",
        meta: {breadcrumb: true},
        name: "permission",
        component: () =>
            import(
                `@/views/Permission.vue`)
    },
    {
        path: "/roles",
        meta: {breadcrumb: true},
        name: "roles",
        component: () =>
            import(
                `@/views/Roles.vue`)
    },
    {
        path: "/uploaded-parcels",
        meta: {breadcrumb: true},
        name: "uploaded-parcels",
        component: () =>
            import(
                `@/views/Shopping/UploadedParcels.vue`)
    },
    {
        path: "/price-settings",
        meta: {breadcrumb: true},
        name: "price-settings",
        component: () =>
            import(
                `@/views/PriceSettings.vue`)
    },
];
