<template>
  <!--  <v-app-bar app color="white" height="74" flat outlined>-->
  <!--    <v-img src="/static/images/logo.jpg" height="16px" contain/>-->
  <!--  </v-app-bar>-->
  <v-app-bar app height="74" flat outlined>
    <div class="page-wrapper d-flex align-center">
      <div @click="$router.push('/shopping')">
        <span class="text-20 text-uppercase primary--text cursor-pointer">Shopping</span>
      </div>
      <div class="d-flex align-center ml-16">
        <v-text-field
            style="min-width: 400px"
            dense
            hide-details
            label="Search"
            outlined
            v-model="search"
        >
          <template v-slot:append-outer class="ma-0">
            <div class="search-button" @click="searchItems">
              <heroicon width="16" height="16" name="searchOutlined" fill="#FFFFFF"/>
            </div>
          </template>
        </v-text-field>
      </div>
      <v-spacer/>
      <v-btn class="ml-5" color="red" text @click="logout">
        log out
      </v-btn>
    </div>
  </v-app-bar>
</template>
<script>
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "AppToolbar",
  components: {},
  data() {
    return {
      items: [],
      model: {},
      search: ""
    };
  },
  computed: {
    ...mapGetters(['username', 'isLoggedIn']),
    ...mapGetters(['user']),
    ...mapActions(['setGlobalErrorMessage']),
    ...mapState(['currentBranch']),

  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
    changeTheme() {
      localStorage.setItem('dark-theme', this.$vuetify.theme.dark = !this.$vuetify.theme.dark)
    },
    selectBranch(branch) {
      this.model = branch
      this.$store.dispatch('selectBranch', branch)
    },
    getBranch() {
      this.model = JSON.parse(localStorage.getItem('branch'))
    },
    searchItems() {
      if (!!this.search) {
        this.$store.dispatch('selectSearchWord', this.search)
        this.redirect()
      }
    },
    clearWord() {
      this.search = ""
      this.$store.dispatch('clearSearchWord', "")
    },
    redirect() {
      window.location = "/result-search/" + this.search
      this.search = ""
    }
  },
  created() {
    this.getBranch()
    this.selectBranch(this.model)
  },
};
</script>
<style>
.text-overflow {
  white-space: nowrap;
  overflow: hidden; /* Обрезаем все, что не помещается в область */
  text-overflow: ellipsis;
  width: 120px;
  color: #2D7289;
  font-size: 20px;
  padding: 0 10px;
}

.role-text {
  font-style: normal;
  font-weight: 400;

  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.6);
}

.role-font-size {
  font-size: 16px;
  line-height: 20px;
}

.search-button {
  cursor: pointer;
  background-color: #2D7289;
  width: 40px;
  height: 40px;
  margin-top: -8px;
  margin-bottom: -4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
</style>

