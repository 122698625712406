import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import vuetify from "./plugins/vuetify";
import router from "./router/";
import http from "./plugins/http";
import rules from "@/api/rules";
import {Ripple} from 'vuetify/lib/directives'
import {mapGetters} from "vuex";

import "./plugins/vuetify";
import "./components/components"
import "./theme/default.sass";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import "@/scss/style.scss";

import HeroIcon from 'vue-heroicons'
import {
    altArrowDownOutline,
    transferHorizontalOutline,
    chatOutline,
    searchOutlined,
    messageConversationOutlined,
    winRarOutlined,
    dialogOutlined,
    smartphoneOutlined,
    uploadOutlined,
    userOutlined,
    signpostOutlined,
    billOutlined,
    dollarOutlined,
    penOutlined,
    printerOutlined,
    settingsOutlined,
    settingsBold,
    squareTopDownOutlined,
    sortVerticalOutlined,
    cloudUploadOutlined,
    eyeOutlined,
    arrowLeftOutlined,
    closeCircleOutlined,

    squareTopUpBold,
    inboxOutBold,
    usersGroupTwoRoundedBold,
    penBold,
    walletBold,
    chartBold,
    passwordBold,
    trashBold,
    lockKeyholeUnlockedBold,
    widgetBold,
    qrCodeBold,
    addCircleBold,
    arrowRightDownBold,
    arrowRightUpBold,
    busBold,
    userBold,
    mapArrowRotateBold,
    radarBold,
    headPhonesBold,
    garageBold,
    globusBold,
    pointOnMapPerspectiveBold,
    cityBold,
    layerBold,
    threeSquaresBold,
    serverMinimalisticBold,
    crossBold,
    filterBold,
    exportBold,
    checkCircleBold,
    closeCircleBold, routingBold, moneyBold, cardBold, cartBold

} from './icons/icons'

HeroIcon.add([
    altArrowDownOutline,
    transferHorizontalOutline,
    chatOutline,
    searchOutlined,
    messageConversationOutlined,
    winRarOutlined,
    dialogOutlined,
    smartphoneOutlined,
    uploadOutlined,
    userOutlined,
    signpostOutlined,
    billOutlined,
    dollarOutlined,
    penOutlined,
    printerOutlined,
    settingsOutlined,
    settingsBold,
    squareTopDownOutlined,
    sortVerticalOutlined,
    cloudUploadOutlined,
    eyeOutlined,
    arrowLeftOutlined,
    closeCircleOutlined,

    squareTopUpBold,
    inboxOutBold,
    usersGroupTwoRoundedBold,
    penBold,
    walletBold,
    chartBold,
    passwordBold,
    trashBold,
    lockKeyholeUnlockedBold,
    widgetBold,
    qrCodeBold,
    addCircleBold,
    arrowRightDownBold,
    arrowRightUpBold,
    busBold,
    userBold,
    mapArrowRotateBold,
    radarBold,
    headPhonesBold,
    garageBold,
    globusBold,
    pointOnMapPerspectiveBold,
    cityBold,
    layerBold,
    threeSquaresBold,
    serverMinimalisticBold,
    crossBold,
    filterBold,
    exportBold,
    checkCircleBold,
    closeCircleBold,
    routingBold,
    moneyBold,
    cardBold,
    cartBold

])
Vue.use(HeroIcon)

Vue.config.productionTip = false;
Vue.prototype.$http = http;
Vue.prototype.$rule = rules;


window.dbg = (data) => {
    console.debug(data)
    return data;
}

const token = localStorage.getItem("token");
if (token) http.defaults.headers.common["Authorization"] = "Bearer " + token;


new Vue({
    router,
    store,
    vuetify,
    mapGetters,
    directives: {
        Ripple,
    },
    render: h => h(App)
}).$mount("#app");
